import Vue from 'vue'
import * as moment from 'moment'

const formatDate = (value) => {
  return value && moment(value).isValid()
    ? moment(value).format('YYYY年MM月DD日')
    : ''
}

const formatTime = (value) => {
  const timeArr = value?.split(':') || []

  if (timeArr.length === 0) return value
  return `${timeArr[0]}:${timeArr[1]}`
}

Vue.filter('formatDate', formatDate)
Vue.filter('formatTime', formatTime)
