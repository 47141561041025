export const PATH = {
  ORDERER_LOGIN: '/orderer/login',
  ORDERER_AUTH_GOOGLE: '/connect/google',
  ORDERER_AUTH_FACEBOOK: '/auth/facebook',
  FORGOT_PASSWORD: '/orderer/password/forgot-password',
  PASSWORD_RESET: '/orderer/password/reset',
  ORDERER_REGISTER: '/orderer/register',
  ORDERER_LOGOUT: '/orderer/logout',
  DASHBOARD: '/orderer/dashboard',
  ORDERER_PROFILE: '/orderer/profile',
  ORDERER_CONTACT: '/orderer/contact-us',
  RECRUITMENTS: '/recruitments',
  ORDERER_RECRUITMENTS: '/orderer/recruitments',
  JOB_APPLICATION: '/job-application',
  EVALUATION_RECORD: '/orderer/evaluations-of-worker',
  CONTACT: '/orderer/contact-us',
  UPDATE_EVALUATION: '/orderer/evaluations',
  ORDERER_CANCEL_JOB_APPLICATION: '/orderer/cancel-recruitment',
  APPROVE_WORKER: '/orderer/select-worker',
  CREATE_EVALUATION: '/orderer/evaluations',
  ORDERER_EVALUATION: '/orderer/evaluations'
}
