import httpRecruitment from '@/api/recruitment'
import snakeCase from 'snakecase-keys'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

const getRecruitments = ({ commit }, payload) => {
  payload = omitBy(snakeCase(payload), isNil)
  return httpRecruitment.getRecruitments(payload).then((res) => {
    commit('RECRUITMENT_UPDATED', res.data)
    return res
  })
}

const createRecruitment = ({ _ }, { formData }) => {
  return httpRecruitment.createRecruitment(formData).then((res) => {
    return res
  })
}

const updateRecruitment = ({ _ }, payload) => {
  return httpRecruitment.updateRecruitment(payload).then((res) => {
    return res
  })
}

const getMyRecruitments = ({ commit }, payload) => {
  const { type = 'apply' } = payload
  let params = { ...payload }
  delete params.type
  params = omitBy(snakeCase(params), isNil)
  return httpRecruitment.getMyRecruitments(params).then((res) => {
    commit('MY_RECRUITMENT_UPDATED', {
      type: type,
      obj: res.data
    })
    return res
  })
}

const updateQuerySearchRecruitment = ({ commit }, payload) => {
  commit('UPDATE_QUERY_SEARCH_RECRUITMENT', payload)
}

const getRecruitmentDetail = ({ commit }, payload) => {
  payload = snakeCase(payload)
  return httpRecruitment.getRecruitmentDetail(payload).then((res) => {
    commit('UPDATE_RECRUITMENT_DETAIL', res.data)
    return res
  })
}

const applyRecruitment = ({ commit }, payload) => {
  payload = snakeCase(payload)
  return httpRecruitment.applyRecruitment(payload).then((res) => {
    commit('APPLY_RECRUITMENT', res.data)
    return res
  })
}

const getEvaluation = ({ commit }, payload) => {
  return httpRecruitment.getEvaluation(payload).then((res) => {
    commit('UPDATE_EVALUATION', res.data)
    return res
  })
}

const addEvaluation = ({ commit }, payload) => {
  payload = snakeCase(payload)
  return httpRecruitment.addEvaluation(payload).then((res) => {
    commit('ADD_EVALUATION', res.data)
    return res
  })
}

const cancelApplyRecruitment = (_, payload) => {
  return httpRecruitment.cancelApplyRecruitment(payload).then((res) => {
    return res
  })
}

const ordererApproveWorker = (_, payload) => {
  payload = snakeCase(payload)
  return httpRecruitment.ordererApproveWorker(payload).then((res) => {
    return res
  })
}

const getRecruitmentRate = async (_, payload) => {
  try {
    payload = snakeCase(payload)
    return await httpRecruitment.getRecruitmentRate(payload).then((res) => {
      return res
    })
  } catch (e) {
    console.log(e)
  }
}

export default {
  getRecruitments,
  getMyRecruitments,
  updateQuerySearchRecruitment,
  getRecruitmentDetail,
  applyRecruitment,
  getEvaluation,
  addEvaluation,
  cancelApplyRecruitment,
  createRecruitment,
  updateRecruitment,
  ordererApproveWorker,
  getRecruitmentRate
}
