import Api from './api'
import { PATH } from './endPoints'

const login = async (params) => {
  return Api.post(PATH.ORDERER_LOGIN, params).then((res) => res.data)
}

const sendMailResetPass = async (params) => {
  return Api.post(PATH.FORGOT_PASSWORD, params).then((res) => res.data)
}

const onResetPassword = async (params) => {
  return Api.post(PATH.PASSWORD_RESET, params).then((res) => res.data)
}

const register = async (params) => {
  return Api.post(PATH.ORDERER_REGISTER, params).then((res) => res.data)
}

const loginSocial = async ({ type = 'google' }) => {
  if (type === 'facebook') {
    return Api.post('/orderer' + PATH.AUTH_FACEBOOK).then((res) => res.data)
  }
  return Api.post(`/orderer/connect/${type}`).then((res) => res.data)
}

const logout = async () => {
  return Api.post(PATH.ORDERER_LOGOUT).then((res) => res.data)
}

const getInfoDashboard = async (id) => {
  return Api.get(`${PATH.DASHBOARD}/${id}`).then((res) => res.data)
}

const getProfile = async (id) => {
  return Api.get(`${PATH.ORDERER_PROFILE}/${id}`).then((res) => res.data)
}

const updateProfile = async (params) => {
  const { id, formData } = params
  return Api.post(`${PATH.ORDERER_PROFILE}/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then((res) => res.data)
}

const deleteProfile = async (id) => {
  return Api.delete(`${PATH.ORDERER_PROFILE}/${id}`).then((res) => res.data)
}

const sendContact = async (params) => {
  const { formData } = params
  return Api.post(PATH.CONTACT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then((res) => res.data)
}

export default {
  login,
  sendMailResetPass,
  onResetPassword,
  loginSocial,
  register,
  logout,
  getInfoDashboard,
  getProfile,
  updateProfile,
  deleteProfile,
  sendContact
}
