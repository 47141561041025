import Api from './api'
import { PATH } from './endPoints'
import { PageModes } from '@/common/constants'

const getRecruitments = async (params) => {
  return Api.get(PATH.RECRUITMENTS, { params: params }).then((res) => res.data)
}

const createRecruitment = async (params) => {
  return Api.post(PATH.ORDERER_RECRUITMENTS, params).then((res) => res.data)
}

const updateRecruitment = async ({ orderId, formData: params }) => {
  return Api.post(`${PATH.ORDERER_RECRUITMENTS}/${orderId}`, params).then(
    (res) => res.data
  )
}

const getMyRecruitments = async ({ url, ...params }) => {
  return Api.get(`/orderer${url}`, { params: params }).then((res) => res.data)
}

const getRecruitmentDetail = async (params) => {
  const { id } = params
  delete params.id
  return Api.get(`${PATH.ORDERER_RECRUITMENTS}/${id}`, { params: params }).then(
    (res) => res.data
  )
}

const applyRecruitment = async (params) => {
  return Api.post(PATH.JOB_APPLICATION, params).then((res) => res.data)
}

const getEvaluation = async (params) => {
  const { id } = params
  delete params.id
  return Api.get(`${PATH.EVALUATION_RECORD}/${id}`, { params: params }).then(
    (res) => res.data
  )
}

const addEvaluation = async ({ evaluations, mode }) => {
  return mode === PageModes.CREATE
    ? Api.post(PATH.UPDATE_EVALUATION, { evaluations }).then((res) => res.data)
    : Api.put(PATH.UPDATE_EVALUATION, { evaluations }).then((res) => res.data)
}

const cancelApplyRecruitment = async (id) => {
  return Api.delete(`${PATH.ORDERER_CANCEL_JOB_APPLICATION}/${id}`).then(
    (res) => res.data
  )
}

const ordererApproveWorker = async (payload) => {
  return Api.put(`${PATH.APPROVE_WORKER}`, payload).then((res) => res.data)
}

const updateRecruitmentRate = async (params) => {
  return Api.put(PATH.UPDATE_EVALUATION, params).then((res) => res.data)
}

const getRecruitmentRate = async (params) => {
  const { id } = params
  delete params.id
  return Api.get(`${PATH.ORDERER_EVALUATION}/${id}`, { params: params }).then(
    (res) => res.data
  )
}

export default {
  getRecruitments,
  getMyRecruitments,
  getRecruitmentDetail,
  applyRecruitment,
  getEvaluation,
  addEvaluation,
  cancelApplyRecruitment,
  createRecruitment,
  updateRecruitment,
  ordererApproveWorker,
  getRecruitmentRate,
  updateRecruitmentRate
}
