import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { routePathNames } from '@/common/constants'

Vue.use(VueRouter)

function checkIsLoggedIn(to, _, next) {
  const token = store.getters['user/token']
  if (!token) {
    next()
  } else {
    next({ name: routePathNames.dashboard })
  }
}

const routes = [
  {
    path: '/',
    name: routePathNames.layout,
    component: () => import('@/views/Layout.vue'),
    redirect: {
      name: routePathNames.dashboard
    },
    beforeEnter: (to, _, next) => {
      const token = store.getters['user/token']
      if (token) {
        if (!to.matched.length) {
          next({ name: routePathNames.dashboard })
        } else {
          next()
        }
      } else {
        next({
          name: routePathNames.login,
          query: {
            redirect: to.fullPath
          }
        })
      }
    },
    children: [
      {
        path: '/dashboard',
        name: routePathNames.dashboard,
        component: () => import('@/views/dashboard/Dashboard.vue')
      },
      {
        path: '/profile',
        name: routePathNames.profile,
        component: () => import('@/views/profile/Profile.vue')
      },
      {
        path: '/history',
        name: routePathNames.history,
        component: () => import('@/views/history/Histories.vue'),
        redirect: {
          name: routePathNames.applicationList
        },
        children: [
          {
            path: '/history/application-list',
            name: routePathNames.applicationList,
            component: () => import('@/views/history/ApplicationList.vue')
          },
          {
            path: '/history/establish-list',
            name: routePathNames.establishList,
            component: () => import('@/views/history/EstablishList.vue')
          },
          {
            path: '/history/complete-list',
            name: routePathNames.completeList,
            component: () => import('@/views/history/CompleteList.vue')
          }
        ]
      },
      {
        path: '/recruitment/create',
        name: routePathNames.createRecruitment,
        component: () => import('@/views/recruitments/Recruitments.vue')
      },
      {
        path: '/history/recruitment-edit/:id',
        name: routePathNames.editRecruitment,
        component: () => import('@/views/recruitments/Recruitments.vue')
      },
      {
        path: '/history/recruitment-detail/:id',
        name: routePathNames.recruitmentDetail,
        component: () =>
          import('@/views/recruitments/RecruitmentDetail/RecruitmentDetail.vue')
      },
      {
        path: '/history/recruitment-duplicate/:id',
        name: routePathNames.duplicateRecruitment,
        component: () => import('@/views/recruitments/Recruitments.vue')
      },
      {
        path: '/contact',
        name: routePathNames.contact,
        component: () => import('@/views/contact/Contact.vue')
      }
    ]
  },
  {
    path: '/login',
    name: routePathNames.login,
    beforeEnter: (to, _, next) => {
      const token = store.getters['user/token']
      if (!token) {
        next()
      } else {
        const { query: { redirect } = {} } = to
        if (redirect) {
          next({ path: redirect })
        } else {
          next({ name: routePathNames.dashboard })
        }
      }
    },
    component: () => import('@/views/auth/login/Login.vue')
  },
  {
    path: '/register',
    name: routePathNames.register,
    beforeEnter: checkIsLoggedIn,
    component: () => import('@/views/auth/register/Register.vue')
  },
  {
    path: '/reset-password',
    name: routePathNames.resetPassword,
    beforeEnter: checkIsLoggedIn,
    component: () => import('@/views/auth/reset-password/ResetPassword.vue')
  },
  {
    path: '/register-success',
    name: routePathNames.registerSuccess,
    beforeEnter: checkIsLoggedIn,
    component: () => import('@/views/auth/register/RegisterSuccess.vue')
  },
  {
    path: '*',
    beforeEnter: (to, _, next) => {
      const token = store.getters['user/token']
      if (token) {
        next({
          name: routePathNames.dashboard
        })
      } else {
        next({
          name: routePathNames.login,
          query: {
            redirect: to.fullPath
          }
        })
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'is-active',
  routes
})

router.onError((error) => {
  if (/loading chunk .* failed./i.test(error.message)) {
    if (!alert('error router' + error.message)) {
      window.location.reload()
    }
  }
})

export default router
